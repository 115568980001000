import * as React from "react"
import styled from "styled-components"
import { Logo } from "./Logo"

const HeaderWrapper = styled.header`
  position: fixed;
  margin: 0 auto;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 576px) {
    padding: 0px;
    max-width: 80vw;
  }

  @media (min-width: 1024px) {
    padding: 20px;
    max-width: 80vw;
  }
`

export const RSVPButton = styled.a`
  padding: 10px 20px;
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: bold;
  font-size: 1rem;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
`

const Header = () => (
  <HeaderWrapper>
    <Logo />
    <RSVPButton href="#form">
      <span>Rückmeldung</span>
    </RSVPButton>
  </HeaderWrapper>
)

export default Header
