import React from "react"
import styled from "styled-components"

const LogoWrapper = styled.div`
  span:nth-child(1),
  span:nth-child(3) {
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 0.5rem;
  }

  span:nth-child(2) {
    font-style: italic;
    font-size: 1.25rem;
    margin-right: 10px;
  }
`

export const Logo = () => {
  return (
    <LogoWrapper>
      <span>Astrid</span>
      <span>und</span>
      <span>Alex</span>
    </LogoWrapper>
  )
}
