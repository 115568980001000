import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import Header from "./Header"

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Didot";
    font-weight: 400;
    src: url("/fonts/Didot-Regular.woff");
    font-display: swap;
  }

  @font-face {
    font-family: "Didot";
    font-weight: 700;
    src: url("/fonts/Didot-LT-Std-Bold.woff");
    font-display: swap;
  }

  @font-face {
    font-family: "Didot";
    font-weight: 400;
    font-style: italic;
    src: url("/fonts/Didot-LT-Std-Italic.woff");
    font-display: swap;
  }

  a {
    color: #fff;
  }

  h1 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1rem;
  }

  h2, h3, h4 {
    font-weight: 400;
    letter-spacing: 0.5rem;
  }

  h2 {
    font-size: 2.125rem;
    text-transform: uppercase;
  }

   h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 140%;
    font-style: italic;
    letter-spacing: 0.25rem;
  }

  body {
    margin: 0;
    color: #fff;
    font-family: "Didot", serif;
    background-color: #1b1c1d;
  }

`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyles />
      <Header />
      <main>{children}</main>
      <footer
        style={{
          marginTop: `2rem`,
        }}
      >
        {/* © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a> */}
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
